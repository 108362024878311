<template>
  <b-card class="p-1">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <b-form-group class="w-50 mb-0">
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Wyszukaj po imieniu, nr. telefonu, emailu, adresie" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Wyczyść </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button
        :to="{ name: 'wholesale-representatives-create' }"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="btn">
        Dodaj
      </b-button>
    </div>
    <b-table
      responsive
      bordered
      striped
      hover
      :items="wholesaleRepresentatives"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn">
      <template #cell(actions)="row">
        <div class="d-flex flex-column">
          <b-button
            :to="{ name: 'wholesale-representatives-edit', params: { id: row.item.id } }"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="btn table-btn"
            size="sm">
            Edytuj
          </b-button>
          <b-button class="mt-1 table-btn" variant="danger" size="sm" @click="destroy(row.item)"> Usuń </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { all, destroy } from '@/http/wholesale-representatives';

import { CountriesFormatter } from '@/formatters/CountriesFormatter';

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      wholesaleRepresentatives: [],
      filter: '',
      filterOn: [],
      fields: [
        {
          key: 'fullname',
          label: 'Imię i nazwisko',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'countries',
          label: 'Kraje',
          sortable: true,
          formatter: (val) => {
            return CountriesFormatter(val);
          },
        },
        { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
        { key: 'actions', label: 'Akcje' },
      ],
    };
  },

  async mounted() {
    await this.fetchWholesaleRepresentatives();
  },

  methods: {
    async fetchWholesaleRepresentatives() {
      try {
        const { data } = await all();

        this.wholesaleRepresentatives = data.representatives;
      } catch (error) {
        console.log(error);
      }
    },
    destroy(wholesaleRepresentative) {
      this.$swal({
        title: 'Jesteś pewny?',
        text: 'Czy chcesz usunąć tego przedstawiciela zagranicznego?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Usuń',
        cancelButtonText: 'Anuluj',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          destroy(wholesaleRepresentative.id)
            .then(() => {
              this.showSuccessNotification(
                'Przedstawiciel usunięty',
                'Pomyślnie usunięto przedstawiciela zagranicznego.',
              );
              this.fetchWholesaleRepresentatives();
            })
            .catch(() => {
              this.showErrorNotification(
                'Problem z usunięciem danych',
                'Wystąpił błąd podczas usuwania przedstawiciela zagranicznego. Skontaktuj się ze swoim developerem.',
              );
            });
        }
      });
    },
  },
};
</script>
