import { all } from '@/constants/enums/countries';

export const CountriesFormatter = (item) => {
  if (!item) {
    return '';
  }

  const countries = item.map((country) => {
    const countryName = all.find((c) => c.key === country);

    return countryName ? countryName.value : '';
  });

  return countries.join(', ');
};
